import React from "react"
import { Link } from "gatsby"
import contactImg from "../../images/contact.png"

import "./ContactSidebar.scss"

const ContactSidebar = ({ waMsg , isBlogpage }) => {
  // console.log("isBlogpage",isBlogpage)
  return (
    <div className="contact-sidebar">
      {isBlogpage ?
         <div className="dealer-cta d-none d-lg-flex">
            <Link to="/contact/" className="btn">
                <i className="icon-mail"></i>
              Get in Touch
           </Link>
           <Link
          href={`https://wa.me/+971585138750?text=${waMsg ? waMsg : ""}`}
          target="_blank"
          className="btn whatsapp"
        >
          <i className="icon-whatsapp"></i>Click to WhatsApp
        </Link>

          </div>
    
     : <div className="dealer-cta d-none d-lg-flex">
        
      
        <a href="tel:+97143806683" className="btn">
          <i className="icon-contact"></i>+971 4 380 6683
        </a>
        <Link to="/contact/" className="btn">
          <i className="icon-mail"></i>
          Request Details
        </Link>
        <Link
          href={`https://wa.me/+971585138750?text=${waMsg ? waMsg : ""}`}
          target="_blank"
          className="btn whatsapp"
        >
          <i className="icon-whatsapp"></i>Click to WhatsApp
        </Link>
      </div>}
      {/* <div className="dealer-info">
        <div className="dealer-wrap">
          <div className="img-wrap zoom">
            <img src={contactImg} alt="contact" />
          </div>
          <div className="dealer-right">
            <span className="dealer-name">Yassir El Ghazi</span>
            <span className="dealer-position">Property Agent</span>
            <span className="dealer-contact">
              <span className="title">Languages:</span>English, Urdu
            </span>
          </div>
        </div>
      </div> */}

    </div>
  )
}

export default ContactSidebar
