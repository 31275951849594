import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import FindProperty from "./MenuList";
import BrandLogo from "../../images/logo.svg";
import BrandLogo02 from "../../images/tab-logo.svg";
import BrandLogo03 from "../../images/logo-desk.svg";
import darkBrandLogo from "../../images/logo-dark.svg";
import darkBrandLogo02 from "../../images/logo-dark-tab.svg";
import darkBrandLogo03 from "../../images/logo-dark-desk.svg";
import "./Header.scss";
import Menudata from "./Menudata";
import Helmet from 'react-helmet'
import MenuList from "./MenuList";
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";
import { isDesktop } from "react-device-detect";
import { useAuthState } from "@starberry/myaccount-website-utils";

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const Header = (props) => {
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      allMenus(sort:"Sorting:asc") {
        id
        Label
        Primary_URL
        Secondary_URL
        Show_In_Top_Nav
        Sorting
        Main_Parent {
          id
          Label
          Primary_URL
          Secondary_URL
        }
        Sub_Parent {
          id
          Label
          Primary_URL
          Secondary_URL            
        }
      }
    }
  }
`)

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [showFindProperty, setShowFindProperty] = useState(false);
  const menu_data = data.glstrapi.allMenus;
  menu_data.sort((a, b) => (a.Sorting > b.Sorting))

  const [state, setState] = useState([]);

  const { state: authState, services: authServices } = useAuthState();
  const isAuthenticated = authServices.isAuthenticated();


  useEffect(() => {
    $(".nav li a").click(function(){
      $(window).scrollTop(0);
    });
    document.querySelector("body").classList.remove("overflow-hidden");
    window.addEventListener("scroll", () => {
      if ($(window).width() < 960) {
        setScroll(window.scrollY > 20);
      } else {
        setScroll(window.scrollY > 120);
      }
    });

    if (menu_data && menu_data.length > 0) {
      var mapMenuList = []
      menu_data.map((item, i) => {
        if (item.Show_In_Top_Nav== true) {
          var menu = {
            name: item.Label,
            link: item.Primary_URL,
            secondaryurl: item.Secondary_URL,
            isActive: false,
            submenu: []
          }
          menu_data.map((submenu, i) => {
            if(submenu.Main_Parent && item.Primary_URL == submenu.Main_Parent.Primary_URL) {
              menu.submenu.push({
                name: submenu.Label,
                link: submenu.Primary_URL,
                secondaryurl: submenu.Secondary_URL,
                isActive: false,
              })
            }
          })
          mapMenuList.push(menu)
        }
      })
      setState(mapMenuList)
    }
  }, []);


  const showMenu = () => {
    setShow(!show);
    if (typeof document !== `undefined`) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      console.log("error");
    }
  };
  const hideMenu = () => {
    setShow(!show);
    if (typeof document !== `undefined`) {
      document.querySelector("body").classList.remove("overflow-hidden");
    } else {
      console.log("error");
    }
    setState(
      state.map((menu) => {
        if (Array.isArray(menu.submenu)) {
          let p = menu.submenu.map((sub) => ({
            ...sub,
            ...{ isActive: false },
          }));
          return { ...menu, ...{ submenu: p }, ...{ isActive: false } };
        } else {
          return { ...menu, ...{ isActive: false } };
        }
      })
    );
  };

  const handlerOpenMenu = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    let isActive = a[index].isActive;
    a[index].isActive = !isActive;
    setState(a);
  };

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation();
    let newState = [...state];
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    newState[index].submenu = a;
    let isActive = newState[index].submenu[i].isActive;
    newState[index].submenu[i].isActive = !isActive;
    setState(newState);
  };

  const mobileNaviagationClasses = show
    ? "navigation-wrap active"
    : "navigation-wrap";

  useEffect(() => {

    try {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
      });
    } catch { }

    let gtagScript;

    if(typeof window !== "undefined"){
      // Load Google Tag Manager script
      gtagScript = document.createElement('script');
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-880104565';
      gtagScript.async = true;
      document.head.insertBefore(gtagScript, document.head.firstChild);

      gtagScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        const gtag = function () { window.dataLayer.push(arguments); };
        gtag('js', new Date());
        gtag('config', 'AW-880104565');

        // Add the conversion event
        if(window.location.pathname == '/off-plan-properties-in-dubai/sky-residences/'){
          gtag('event', 'conversion', {'send_to': 'AW-880104565/XHMmCKzP974ZEPWo1aMD'});
        }
      };
      // Load Google Tag Manager script

    }

    return () => {
      if (gtagScript) {
        document.head.removeChild(gtagScript);
      }
    };

  }, []);

  const isFixed = props.alt || (props.layout === "Static") ? true : false
  return (

    <header className={`header ${props.additionalClass ? props.additionalClass : ''} ${scroll || isFixed ? 'fixed' : ''}`}>
      
      <Helmet>     
     
      <script type="application/ld+json">{`
                  {
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                      "name": "McCone Properties",
                      "image": "https://www.mcconeproperties.com/static/tab-logo-5bb68d770f8537622bc47bf26c663cec.svg",
                      "telephone": ["+971 4 380 6683"],
                      "areaServed": "Dubai Marina, Dubai hills estate, Dubai creek harbour, business bay, Palm Jumeirah, Jumeirah Village Circle",
                      "url": "https://www.mcconeproperties.com/",
                      "description": "Search, Buy, Rent or List Your Properties for Sale on mcconeproperties.com. Search for apartments and villas for sale in Dubai on mcconeproperties.com and get good deals.",
                      "address" : {
                            "@type" : "PostalAddress",
                            "streetAddress" : "The Onyx Tower 2",   
                            "addressLocality" : "The Greens",  
                            "postalCode" : "P305 & P306"
                            },
                    "openingHoursSpecification" : {
                    "@type" : "OpeningHoursSpecification",
                    "dayOfWeek" : {
                    "@type" : "DayOfWeek",
                    "name" : "Mo, Tu, We, Th, Fr 09:00-17:00 "
                    }
            },
                    "sameAs": [
                        "https://www.facebook.com/mcconeproperties/",
                        "https://www.instagram.com/mcconeproperties/?hl=en", "https://www.youtube.com/channel/UC-A0gljutHXS7MbZO6jldkQ",
                        "https://www.linkedin.com/company/mccone-properties/",
                        "https://www.tiktok.com/@mccone_properties" ,
                        "https://twitter.com/mcconeprops?lang=en"
          ]
          }      
        }
        `}
     </script>

      </Helmet>
 
      <Container>
        <Row>
          <Col sm="12">
            <div className="header-wrapper">
              <div className="logo light">
                <Link to="/">
                  <picture>
                    <source
                      media="(min-width:1400px)"
                      srcSet={BrandLogo03}
                      alt="logo"
                    />
                    <source
                      media="(min-width:768px)"
                      srcSet={BrandLogo02}
                      alt="logo"
                    />

                    <img src={BrandLogo} alt="logo" className="brand-logo" width={isDesktop ? 150 : 100} height={isDesktop ? 48 : 32} loading="lazy"/>

                  </picture>
                </Link>
              </div>
              <div className="logo dark">
                <Link to="/">
                  <picture>
                    <source
                      media="(min-width:1400px)"
                      srcSet={darkBrandLogo03}
                      alt="logo"
                    />
                    <source
                      media="(min-width:768px)"
                      srcSet={darkBrandLogo02}
                      alt="logo"
                    />

                    <img src={darkBrandLogo} alt="logo" className="brand-logo" width={isDesktop ? 150 : 100} height={isDesktop ? 48 : 32} loading="lazy" />
                  </picture>
                </Link>
              </div>
              <div className="nav-right d-flex align-items-center d-xl-flex">
                <nav className="nav d-none d-xl-block">
                  <ul className="d-flex mb-0 align-items-center">
                    {menu_data.map((menuitem, key) => {

                      var get_submenu_item = menu_data.filter(subitem => subitem?.Main_Parent?.Primary_URL === menuitem?.Primary_URL)

                      return <>
                        {menuitem.Show_In_Top_Nav === true &&
                        <li className="current-link">
                          {menuitem.Secondary_URL == "#" ?
                          <a href="javascript:;">{menuitem.Label}</a>
                          :
                          <Link to={`/${menuitem.Primary_URL}/`}>{menuitem.Label}</Link>
                          }
                          {get_submenu_item?.length > 0 && <i className="icon-white-arrow-down"></i>}
                          {get_submenu_item?.length > 0 && <i className="icon-dark-menu"></i>}

                          {get_submenu_item?.length > 0 && get_submenu_item.map((subitem, key) => {
                          return<>
                          <div className="backdrop">                            
                              <MenuList menu_data={menu_data} parenturl={menuitem.Primary_URL}/>
                          </div>
                          </>
                          })}

                        </li>
                        }
                      </>
                    })}
                  </ul>
                </nav>
                <div className="menu-wrap d-flex d-md-none">
            
                      <Link href={myaccountURL} className="icons d-inline-block">
                        {isAuthenticated?
                        <i className="icon-user-loggedin"></i>  
                        :
                        <i className="icon-user"></i>
                        }
                      </Link>
               
                    
                  <a href="tel:+97143806683" className="icons d-inline-block">
                    <i className="icon-phone"></i>
                  </a>
                  <button className="hamburger-btn" onClick={showMenu}>
                    <i className="icon-menu"></i>
                  </button>
                </div>
                <div className="nav-contact d-none d-md-flex align-items-center">
                  <div className="call-us d-flex">
                    <div className="d-flex header-icons">
                      <Link to="/list-my-property/" className="icons">
                        <i className="icon-home"></i>
                      </Link>
                      <Link to="/list-my-property/" className="sm-text">List My Property</Link>
                    </div>

                    <div className="d-flex header-icons sb-myacc">
                      <Link href={myaccountURL} className="icons">
                        {isAuthenticated?
                        <i className="icon-user-loggedin"></i>  
                        :
                        <i className="icon-user"></i>
                        }
                      </Link>
                      <Link href={myaccountURL} className="sm-text d-none d-md-flex">{ isAuthenticated ? 'MyAccount' : 'Login' }</Link>

                    </div>

                    <div className="menu-mobile d-flex header-icons d-xl-none">
                      <button className="hamburger-btn" onClick={showMenu}>
                        <i className="icon-menu"></i>
                      </button>
                      <a href="javascript:;" className="sm-text">Menu</a>
                    </div>

                  </div>
                </div>
              </div>
              <div className={mobileNaviagationClasses}>
                <div className="btn-wrapper-menu d-flex justify-content-end">

                  <button
                    type="button"
                    className="close-btn"
                    onClick={hideMenu}
                  >
                    <i className="icon-cross"></i>
                  </button>
                </div>
                <div className="navigation">
                  <ul className="menu-accordion">
                    {state.map((item, i) => (
                      <Menudata
                        key={i}
                        index={i}
                        handlerOpenMenu={handlerOpenMenu}
                        handlerOpenSubMenu={handlerOpenSubMenu}
                        item={item}
                      />
                    ))}
                  </ul>

                </div>
                <div className="btn-wrapper d-flex ">
                  <Row>
                    <Col sm={12} md={6}>
                      <a href={myaccountURL} className="btn btn-primary">
                        Sign Up / Login <i className="side-arrow"></i>
                      </a>
                    </Col>
                    <Col sm={12} md={6}>
                      <a href="/list-my-property/" className="btn">
                        List Your Property<i className="side-arrow"></i>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {showFindProperty && (
        <div className="backdrop">
          <FindProperty onClose={setShowFindProperty} />
        </div>
      )}
    </header>
  );
};

export default Header;
