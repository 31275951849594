import React, { useRef } from 'react';
import { Link } from "gatsby";
import './MenuList.scss';

const MenuList = (props) => {
	const locationRef = useRef();
	const submitHandler = (event) => {
		event.preventDefault();
		//console.log("submit");
	}
	return (
		<div className="menu-list">
			<div className="close-btn d-md-none">
				<button onClick={() => props.onClose()}>
				</button>
			</div>
			<div className="content-wrapper">
				{props.menu_data.map((submenu) => {
					return <>
						{submenu.Main_Parent && submenu.Main_Parent.Primary_URL === props.parenturl &&
							<div className='content'>
								<div className='content-info'>
									<span><Link to={submenu.Secondary_URL?submenu.Secondary_URL:`/${submenu.Main_Parent.Primary_URL}/${submenu.Primary_URL}/`}>{submenu.Label}</Link></span>
								</div>
							</div>
						}
					</>
				})}
			</div>
		</div>
	)
}

export default MenuList;
