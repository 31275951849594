import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { useWindowSize } from "../../hooks/window-size";
import "./Btn.scss";

const ButtonGroup = (props) => {
  const [windowWidth] = useWindowSize();

  return (
    <section className={`fix-btn details-fixed ${props.isGhostPage? "ghost" : ""}`}>
    <Container>
      <Row>
        <Col md={12} className="d-lg-none">
          {!props.isGhostPage &&
          <div className="btn-groups">
            <Link href="tel:+97143806683" className="btn">
              <i className="icon-dialer"></i>
              <span>
                Contact
              </span>
              {/* <span className="d-none d-md-flex">
              +971 58 531 3748
              </span> */}
            </Link>              
            <Link to={`/book-a-viewing?pid=${props.id}/`} className="btn" >
              <i className="icon-envelope"></i>
              <span >
                Viewing
              </span>
              {/* <span className="d-none d-md-flex">
                Request Details
              </span> */}
            </Link>
            <a href={`https://wa.me/+971585138750?text=${props.whatsAppMessage}`} target="_blank" className="btn">
              <i className="icon-whatsapp"></i>
              <span>
                WhatsApp
              </span>
              {/* <span className="d-none d-md-flex">
                Click to WhatsApp
              </span> */}
            </a>
          </div>
          }
          {props.isGhostPage &&
          <div className="btn-groups ghost">
            <Link href="/contact/" className="btn"> 
            
                Get in Touch
           
            </Link> 
          </div>
          }
        </Col>
      </Row>
    </Container>
    </section>
  );
};

export default ButtonGroup;
