import React from "react";
import GenerateLink from "../common/site/GetLink"
const Menudata = (props) => {
  return (
    <li
      className={
        props.item.submenu && props.item.isActive
          ? "active card-header card-header-parent"
          : "card-header card-header-parent"
      }
      onClick={() => props.handlerOpenMenu(props.index)}
    >

      {props.item.secondaryurl && props.item.secondaryurl =="#"?
      <a className="d-block menu-link" href="javascript:;">{props.item.name}
      {props.item.submenu && props.item.submenu.length > 0 && props.item.isActive ? (
          <i className="icon-minus"></i>
        ) : (
          props.item.submenu && props.item.submenu.length > 0 && <i className="icon-plus"></i>
        )}
      </a>
        :
        <GenerateLink class="d-block menu-link" link={props.item.link} secondarylink={props.item.secondaryurl}>
        {props.item.name}
        {props.item.submenu && props.item.submenu.length > 0  && props.item.isActive ? (
          <i className="icon-minus"></i>
        ) : (
          props.item.submenu && props.item.submenu.length > 0 && <i className="icon-plus"></i>
        )}
      </GenerateLink> 
      }
      {props.item.submenu && props.item.submenu.length > 0 && props.item.isActive && (
        <ul className="submenu-list">
          {props.item.submenu.map((item, i) => (
            <li
              className={
                item.submenu && item.isActive
                  ? "active card-header card-header-child"
                  : "card-header card-header-child"
              }
              onClick={(e) =>
                props.handlerOpenSubMenu(e, props.index, i, "submenu")
              }
            >
              <GenerateLink link={item.link} class="d-block sub-menu-link" secondarylink={item.secondaryurl}>

                {item.name}

                {item.submenu && item.isActive ? (
                  <i className="icon-minus"></i>
                ) : (
                  item.submenu && <i className="icon-plus faded"></i>
                )}
              </GenerateLink>
              {item.submenu && item.isActive && (
                <ul className="inner-submenu">
                  {item.submenu.map((item) => (
                    <li
                      onClick={(e) => e.stopPropagation()}
                      className="menu-accordion"
                    >
                      <GenerateLink secondarylink={item.secondaryurl} link={item.link}>{item}</GenerateLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default Menudata;
