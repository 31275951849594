import * as React from "react";
import Header from "../../components/Header/Header";
import PropertyBar from "../../components/PropertyHeader/PropertyHeader";
import PropertyBanner from "../../components/PropertyBanner/PropertyBanner";
import PropertyInfo from "../../components/PropertyInfo/PropertyInfo";
import PropertyDetails from "../../components/PropertyDescription/PropertyDescription";
import ContactCard from "../../components/ContactSidebar/ContactSidebarNoStaff";
import Facility from "../../components/Facilities/Facilities";
import SimilarProperty from "../../components/SimilarProperty/Property";
import Footer from "../../components/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/bootstrap-overrides.scss";
import "../../scss/global.scss";
import "../../scss/grunticon.scss";
import "./PropertyDetails.scss";
import PropertyMap from "../../components/PropertyMap/PropertyMap";
import AboutProperty from "../../components/AboutProperty/AboutProperty";
import Btn from "../../components/BottomBtn/Btn";
import { useWindowSize } from "../../hooks/window-size";
import MortgageRepayments from "../../components/MortgageRepayments/MortgageRepayments";
import { AnimationOnScroll } from "react-animation-on-scroll";

// markup
const Property = () => {
  const [windowWidth] = useWindowSize();

  const HeaderCard = [
    {
      title: "Mortgage Repayments",
      Content: "Calculate your monthly repayments with our mortgage calculator",
    },
  ];

  return (
    <>
      <Header additionalClass="white" />
      {/* <PropertyBar />
      <PropertyBanner />
      <div className="details-container">
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
          <Container>
            <Row>
              <Col md={12} lg={8} xl={8}>
                <PropertyInfo />
                <PropertyDetails />
                <Facility />
                {windowWidth < 768 ? <PropertyMap /> : <AboutProperty />}
                <MortgageRepayments />
              </Col>
              <Col lg={4} xl={4}>
                <ContactCard />
              </Col>
            </Row>
          </Container>
        </AnimationOnScroll>
      </div>
      <SimilarProperty />
      <Btn /> */}
      <Footer />
    </>
  );
};

export default Property;
